import { Collapse } from "flowbite";

class Menu {
  constructor() {
    this.targetEl = document.getElementById("navbar-default");
    this.triggerEl = document.getElementById("menu-button");
  }

  closeMenu = () => {
    const collapse = new Collapse(this.targetEl, this.triggerEl);
    collapse.collapse();
  };
}

export default Menu;
