import gsap from "gsap";
import { Tween } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomCarousel from "./customCarousel";
const customCarousel = new CustomCarousel();

class Scrolling {
  constructor() {
    gsap.registerPlugin(ScrollTrigger, Tween);
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.maxScreen = 768;
    this.desktop = this.screenWidth >= this.maxScreen;
    this.topBase = (this.screenHeight * 10) / 100;
  }

  reset = () => {
    ScrollTrigger.killAll();
  };

  homeParallaxBanner = () => {
    const banners = gsap.utils.toArray(".home-banner-item");

    Tween.to(banners, {
      xPercent: -(105 * banners.length),
      ease: "none",
      scrollTrigger: {
        trigger: ".home-banner",
        pin: true,
        scrub: true,
        start: "0 0",
        end: `+=${this.screenHeight}`,
      },
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".home-title",
        scrub: true,
        start: "0 0",
        end: `+=${this.screenHeight / 2}`,
      },
    });

    tl.to(".home-title", {
      y: -this.screenHeight,
      rotate: "5deg",
    });

    tl.to("header", {
      background: "rgba(255,255,255,0.7)",
      ease: "none",
    });
    customCarousel.generalCarousel();
  };

  homeScrollGroup = () => {
    Tween.to(".unit-and-price", {
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      height: this.screenWidth >= this.maxScreen ? "100vh" : "auto",
      position: this.screenWidth >= this.maxScreen ? "fixed" : "relative",
      ease: "none",
      scrollTrigger: {
        trigger: ".unit-and-price",
        scrub: 1,
        start: "0 100%",
        end: `+=${this.screenHeight}`,
      },
    });

    Tween.fromTo(
      ".discount-price",
      {
        y: 0,
        x: -this.screenWidth,
        zIndex: 3,
      },
      {
        y: () => 0,
        x: (index, target) => {
          return this.desktop
            ? this.screenWidth / 6 -
                (target.offsetWidth - target.offsetWidth / 5)
            : 0;
        },
        zIndex: 3,
        ease: "none",
        scrollTrigger: {
          trigger: ".discount-price",
          scrub: 1,
          start: "0 90%",
          end: "0 95%",
        },
      }
    );

    Tween.fromTo(
      ".monthly-price",
      {
        y: 0,
        x: () => {
          return this.screenWidth >= this.maxScreen
            ? -this.screenWidth
            : this.screenWidth;
        },
        zIndex: 2,
      },
      {
        y: (index, target) => {
          const targetWidth = target.offsetWidth;
          return this.desktop ? -(targetWidth / 2.8) : 0;
        },
        x: (index, target) => {
          return this.desktop
            ? this.screenWidth / 3 -
                (target.offsetWidth + target.offsetWidth / 6)
            : 0;
        },
        zIndex: 2,
        ease: "none",
        scrollTrigger: {
          trigger: ".monthly-price",
          scrub: 1,
          start: "0 90%",
          end: "0 95%",
        },
      }
    );

    Tween.fromTo(
      ".cashback-price",
      {
        y: 0,
        x: () => {
          return this.screenWidth >= this.maxScreen
            ? -this.screenWidth
            : this.screenWidth;
        },
        zIndex: 1,
      },
      {
        y: (index, target) => {
          const targetWidth = target.offsetWidth;
          return this.desktop ? -(targetWidth / 1.4) : 0;
        },
        x: (index, target) => {
          return this.desktop
            ? this.screenWidth / 2.2 -
                (target.offsetWidth + target.offsetWidth / 6)
            : 0;
        },
        zIndex: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".cashback-price",
          scrub: 1,
          start: "0 98%",
          end: "0 100%",
        },
      }
    );

    Tween.fromTo(
      ".unit-about",
      {
        y: 0,
        x: () => {
          return this.desktop ? this.screenWidth : -this.screenWidth;
        },
      },
      {
        y: 0,
        x: (index, target) => {
          const deskPos = target.offsetWidth / 2;
          return this.desktop ? -deskPos : 0;
        },
        ease: "none",
        scrollTrigger: {
          trigger: ".unit-about",
          scrub: true,
          start: "0 90%",
          end: "0 95%",
        },
      }
    );

    Tween.fromTo(
      ".unit-pop",
      {
        y: 0,
        x: this.screenWidth,
      },
      {
        y: (index, target) => {
          const deskPos = target.offsetHeight - target.offsetHeight / 4;
          return this.desktop ? -deskPos : 0;
        },
        x: (index, target) => {
          const deskPos = target.offsetWidth / 4;
          return this.desktop ? -deskPos : 0;
        },
        ease: "none",
        scrollTrigger: {
          trigger: ".unit-pop",
          scrub: true,
          start: "0 90%",
          end: "0 95%",
        },
      }
    );

    Tween.fromTo(
      ".unit-bold",
      {
        y: 0,
        x: () => {
          return this.screenWidth >= this.maxScreen
            ? this.screenWidth
            : -this.screenWidth;
        },
      },
      {
        y: (index, target) => {
          const base = target.offsetHeight * 2;
          const deskPos = base - base / 4;
          return this.desktop ? -deskPos : 0;
        },
        x: (index, target) => {
          return this.desktop
            ? this.screenWidth / 2 -
                (target.offsetWidth + target.offsetWidth / 2)
            : 0;
        },
        ease: "none",
        scrollTrigger: {
          trigger: ".unit-bold",
          scrub: true,
          start: "0 90%",
          end: "0 90%",
        },
      }
    );

    // New content animation
    Tween.fromTo(
      ".additional-info",
      { opacity: 0 },
      {
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".additional-info",
          start: "top bottom", // Start animation when the element appears at the bottom of the viewport
          end: "top center", // End animation when the element reaches the middle of the viewport
          scrub: true
        }
      }
    );

    if (this.screenWidth >= this.maxScreen) {
      const unitPriceOut = gsap.timeline({
        scrollTrigger: {
          trigger: ".scroll-space",
          scrub: true,
          start: "-50% 100%",
          ease: "none",
          end: `+=${this.screenHeight}`,
        },
      });
      unitPriceOut.to(".discount-price", {
        y: -this.screenHeight,
      });
      unitPriceOut.to(".monthly-price", {
        y: -this.screenHeight,
      });
      unitPriceOut.to(".cashback-price", {
        y: -this.screenHeight * 2,
      });
      unitPriceOut.to(".unit-about", {
        x: (index, target) => {
          return -(target.offsetWidth * 2 - target.offsetWidth / 4);
        },
        y: (index, target) => {
          return target.offsetHeight / 4;
        },
      });
      unitPriceOut.to(".unit-pop", {
        x: (index, target) => {
          return -(target.offsetWidth - target.offsetWidth / 8);
        },
      });
      unitPriceOut.to(".unit-bold", {
        y: (index, target) => {
          return -(target.offsetHeight * 2 - target.offsetHeight / 4);
        },
      });
      unitPriceOut.to(".units", {
        y: -this.screenHeight,
      });
      // Adding animation for .additional-info
      unitPriceOut.fromTo(".additional-info", {
        opacity: 0
      }, {
        opacity: 1,
        duration: 1,
        ease: "none"
      });
    }
    customCarousel.generalCarousel();
  };

  aboutScroll = () => {
    const bannerEl = document.querySelector(".about-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#about-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#about-content",
        scrub: true,
        start: "0 40%",
        end: "0 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });

    tl.to(".about-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  popScroll = () => {
    const bannerEl = document.querySelector(".pop-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#pop-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#pop-content",
        scrub: true,
        start: "top 40%",
        end: "top 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });
    
    tl.to(".pop-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  boldScroll = () => {
    const bannerEl = document.querySelector(".bold-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#bold-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#bold-content",
        scrub: true,
        start: "top 40%",
        end: "top 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });
    
    tl.to(".bold-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  boldxlScroll = () => {
    const bannerEl = document.querySelector(".boldxl-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#boldxl-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#boldxl-content",
        scrub: true,
        start: "top 40%",
        end: "top 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });
    
    tl.to(".boldxl-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  popezyScroll = () => {
    const bannerEl = document.querySelector(".popezy-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#popezy-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#popezy-content",
        scrub: true,
        start: "top 40%",
        end: "top 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });
    
    tl.to(".popezy-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  zenxzenScroll = () => {
    const bannerEl = document.querySelector(".zenxzen-banner");
    const bannerGap = (bannerEl.offsetHeight * 10) / 100;
    const marginAboutEl =
      bannerEl.offsetHeight -
      (bannerGap < 24 ? 24 : bannerGap > 100 ? 100 : bannerGap);
    Tween.to("#zenxzen-content", { top: marginAboutEl });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#zenxzen-content",
        scrub: true,
        start: "top 40%",
        end: "top 10%",
      },
    });

    tl.to("header", {
      background: "rgba(0,0,0,0.7)",
      ease: "none",
    });
    
    tl.to(".zenxzen-banner", {
      opacity: 0,
      ease: "none",
    });

    customCarousel.generalCarousel();
  };

  homeScroll = () => {
    if (this.screenWidth < this.maxScreen) {
      
      customCarousel.homeMobileCaraousel();

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".home-title",
          scrub: true,
          start: "0 70%",
          end: `+=${this.screenHeight}`,
        },
      });

      tl.to("header", {
        background: "rgba(255,255,255,0.7)",
        ease: "none",
      });
      customCarousel.generalCarousel();
    } else {
      this.homeParallaxBanner();
    }
    this.homeScrollGroup();
  };
}

export default Scrolling;
