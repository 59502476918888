import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { Tween } from "gsap/gsap-core";
import { loadScrollAnimation, modifyLogo, resetScrollAnimation} from "./component";
import contactSales from "./contactus";

gsap.registerPlugin(Tween)

class CustomTransition extends Highway.Transition {
  in({ from, to, done }) {
    window.scrollTo(0, 0);
    from.remove();
    Tween.fromTo(
      to,
      1,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: () => {
          modifyLogo();
          loadScrollAnimation();
          contactSales();
          done();
        },
      }
    );
  }
  out({ from, done }) {
    Tween.fromTo(
      from,
      1,
      { opacity: 1 },
      {
        opacity: 0,
        onComplete: () => {
          resetScrollAnimation();
          done();
        },
      }
    );
  }
}

export default CustomTransition;
