const contactSales = () => {
    const button = document.querySelector("#button-sales");
    const name = document.querySelector("#form-name");
    const email = document.querySelector("#form-email");
    const phone = document.querySelector("#form-phone");
    const help = document.querySelector("#form-helper");
    const success = document.querySelector("#form-success");
    const loading = document.querySelector("#loading-form");
  
    button.addEventListener("click", (event) => {
      event.preventDefault();
  
      let valid = true;
      document.querySelector("#name-info").textContent = "";
      document.querySelector("#email-info").textContent = "";
      document.querySelector("#phone-info").textContent = "";
      document.querySelector("#help-info").textContent = "";
  
      if (name.value === "") {
        valid = false;
        document.querySelector("#name-info").textContent =
          "Please enter your name.";
      }
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(email.value)) {
        valid = false;
        document.querySelector("#email-info").textContent =
          "Please enter a valid email address.";
      }
      if (isNaN(parseInt(phone.value))) {
        valid = false;
        document.querySelector("#phone-info").textContent =
          "Please enter a valid phone number.";
      }
      if (help.value === "") {
        valid = false;
        document.querySelector("#help-info").textContent =
          "Please choose what we can help you.";
      }
  
      if (valid) {
        button.setAttribute("disabled", true);
        loading.classList.replace("hidden", "inline");
  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json'); 
        fetch("https://artzyhauz.rollinghills.id/api/mailing.php", {
          method: "POST",
          body: JSON.stringify({ 
            email: email.value,
            phone: phone.value,
            name: name.value,
            help: help.value
          }),
          headers,
        })
          .then(() => {
            name.value = "";
            email.value = "";
            phone.value = "";
            help.value = "";
            loading.classList.replace("inline", "hidden");
            success.classList.remove("hidden");
            document.querySelector("#form-success-message").textContent = help.value === 'download-brochure' ? "Please be patient while our sales team is contacting you soon." : "We have sent a eBrochure to your email";
          });
      }
    });
  };

  export default contactSales;