import Flickity from "flickity";

class CustomCarousel {
  constructor() {
    this.generalOptions = {
      defaultPosition: 0,
      interval: 7000,
    };
  }

  generalCarousel = () => {
    const generalEl = document.querySelector(".carousel-all");
    new Flickity(generalEl, {
      cellAlign: "center",
      contain: false,
      autoPlay: true,
      pageDots: false,
      prevNextButtons: true,
      lazyLoad: false,
      wrapAround: true,
    });
  };

  homeMobileCaraousel = () => {
    const elemHome = document.querySelector(".home-mobile-carousel");
    new Flickity(elemHome, {
      cellAlign: "left",
      contain: true,
      autoPlay: false,
      pageDots: false,
      prevNextButtons: false,
      setGallerySize: true,
      wrapAround: false,
      percentPosition: true,
      adaptiveHeight: false,
      lazyLoad: false,
    });
  };
}

export default CustomCarousel;
