import Scrolling from "./scrolling";

const scrolling = new Scrolling();

const modifyLogo = () => {
  const pathname = window.location.pathname;
  const blackLogo = document.querySelector("#logo-black");
  const whiteLogo = document.querySelector("#logo-white");
  blackLogo.classList.remove("block");
  whiteLogo.classList.remove("block");
  blackLogo.classList.remove("hidden");
  whiteLogo.classList.remove("hidden");
  if (pathname === "/index.html" || pathname === "/" || pathname === "/index" || pathname === "/v2" || pathname === "/v2.html") {
    blackLogo.classList.add("block");
    whiteLogo.classList.add("hidden");
    document.querySelector("body").classList.replace('bg-gray-light', 'bg-white')
  } else {
    whiteLogo.classList.add("block");
    blackLogo.classList.add("hidden");
    document.querySelector("body").classList.replace('bg-white', 'bg-gray-light')
  }
};

const loadScrollAnimation = () => {
  const pathname = window.location.pathname;
  if (pathname === "/" || pathname === "/index" || pathname === "/index.html") {
    scrolling.homeScroll();
  }
  if (pathname === "/about" || pathname === "/about.html") {
    scrolling.aboutScroll();
  }
  if (pathname === "/bold" || pathname === "/bold.html") {
    scrolling.boldScroll();
  }
  if (pathname === "/pop" || pathname === "/pop.html") {
    scrolling.popScroll();
  }
  if (pathname === "/boldxl" || pathname === "/boldxl.html") {
    scrolling.boldxlScroll();
  }
  if (pathname === "/popezy" || pathname === "/popezy.html") {
    scrolling.popezyScroll();
  }
  if (pathname === "/zenxzen" || pathname === "/zenxzen.html") {
    scrolling.zenxzenScroll();
  }
};

const resetScrollAnimation = () => {
    scrolling.reset();
}

export { modifyLogo, loadScrollAnimation, resetScrollAnimation };
